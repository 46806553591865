import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { NavigationToolbar } from '../../components/navigation/navigation-toolbar.component'
import { Footer } from '../../components/footer/footer.component'

import './privacy.styles.css'
import useAnalyticsTracking from '../../hooks/useAnalyticsTracking'

export const PrivacyPolicyContent = () => {
    const { t } = useTranslation()
    return (
    <div className="privacy-content">
        <div className="privacy-table">
            <h1>{ t("privacy.title") }</h1>
            <p className="lastUpdated">{ t("privacy.last-changed") } 23.01.22</p>
            <p className="pp-paragraph">{ t("privacy.para1") }</p>
            <p className="pp-paragraph">{ t("privacy.para2") }</p>
            <p className="pp-paragraph">{ t("privacy.para3") }</p>

            <h2>{ t("privacy.controller-title") }</h2>
            <p className="pp-paragraph">{ t("privacy.controller-para1") }</p>

            <h2>{ t("privacy.purpose-title") }</h2>
            <p className="pp-paragraph">{ t("privacy.purpose-para1") }</p>
            <ul className="pp-paragraph">
                <Trans i18nKey="privacy.purpose-para2"><li /><li /><li /></Trans>
            </ul>

            <h2>{ t("privacy.personal-title") }</h2>
            <p className="pp-paragraph">{ t("privacy.personal-para1") }</p>
            <ul className="pp-paragraph">
                <Trans i18nKey="privacy.personal-para2"><li /><li /><li /></Trans>
            </ul>

            <h2>{ t("privacy.storage-title") }</h2>
            <p className="pp-paragraph">{ t("privacy.storage-para1") }</p>

            <h2>{ t("privacy.processors-title") }</h2>
            <p className="pp-paragraph">{ t("privacy.processors-para1") }</p>

            <h2>{ t("privacy.rights-title") }</h2>
            <p className="pp-paragraph">{ t("privacy.rights-para1") }</p>
            <p className="pp-paragraph"><Trans i18nKey="privacy.rights-para2"><b/></Trans></p>
            <p className="pp-paragraph"><Trans i18nKey="privacy.rights-para3"><b/></Trans></p>
            <p className="pp-paragraph"><Trans i18nKey="privacy.rights-para4"><b/></Trans></p>
            <p className="pp-paragraph"><Trans i18nKey="privacy.rights-para5"><b/></Trans></p>
            <p className="pp-paragraph"><Trans i18nKey="privacy.rights-authority"><b/></Trans></p>
            <p className="pp-paragraph">{ t("privacy.rights-para6") }</p>

            <h2>{ t("privacy.secure-title") }</h2>
            <p className="pp-paragraph">{ t("privacy.secure-para1") }</p>

            <h2>{ t("privacy.analytics-title") }</h2>
            <p className="pp-paragraph"><Trans i18nKey="privacy.analytics-para1"><b/></Trans></p>
            <p className="pp-paragraph">{ t("privacy.analytics-para2") }</p>
            <p className="pp-paragraph"><Trans i18nKey="privacy.analytics-para3"><b/></Trans></p>

            <h2>{ t("privacy.updates-title") }</h2>
            <p className="pp-paragraph">{ t("privacy.updates-para1") }</p>

            <h2>{ t("privacy.contact-title") }</h2>
            <p className="pp-paragraph">Kim Hafr (contact@getloadedapp.com)</p>
        </div>
    </div>
)
}

function Privacy() {
    useAnalyticsTracking()
    return (
        <div className="wrapper">
            <NavigationToolbar includeHome={ true } />
            <PrivacyPolicyContent />
            <Footer />
        </div>
    );
}

export default Privacy;
