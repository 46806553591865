import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser'

import './footer.styles.css'

const sendMail = () => {
    mixpanel.track('Contact', { 'source': "Mail" })
    window.open('mailto:contact@getloadedapp.com?subject=My%20Feedback', "_blank")
}

export const Footer = () => {
    const { t } = useTranslation()
    return (
        <footer className="footer">
            <div className="footer-links">
                <a href="https://www.instagram.com/loadedapp/" target="_blank" rel="noopener noreferrer">
                    <i className="material-iconic instagram" />
                </a>
                <a href="https://www.facebook.com/loadedapp" target="_blank" rel="noopener noreferrer">
                    <i className="material-iconic facebook" />
                </a>
                <a href="https://twitter.com/loaded_app" target="_blank" rel="noopener noreferrer">
                    <i className="material-iconic twitter" />
                </a>
            </div>
            <p className="footer-copyright">Loaded © 2022</p>
            <div className="footer-legal">
                <Link to={"/privacy" }>{t('footer.privacy')}</Link>
                <span>•</span>
                <Link to={"/terms-of-use" }>{t('footer.terms')}</Link>
                <span>•</span>
                <Link to="#" onClick={ sendMail }>{t('footer.contact')}</Link>
            </div>
        </footer>
    )
}
