import React, { useState, useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'

import './navigation-toolbar.styles.css'
import resolvedLanguage, { languageCode } from "../../utils/resolvedLanguage";

const toggleLanguage = () => {
    const resolvedLanguage = i18n.resolvedLanguage
    const newValue = resolvedLanguage === languageCode.nb ? languageCode.en : languageCode.nb
    mixpanel.track('Change language', { 'to': newValue });
    i18n.changeLanguage(newValue)
}

const HomeButton = ({title}: HomeButtonType) => (
    <Link
        className={ "nav-link" }
        to={'/'}>{ title }
    </Link>
)

const ChangeLanguageButton = ({code}: ChangeLanguageButtonType) => {
    return (
        <img
            className={ "nav-link" }
            onClick={ toggleLanguage }
            src={ `/assets/${code}.svg` }
            alt="Change language"
            width="25px"
            height="18.5px"
        />
    )
}

export function NavigationToolbar({includeHome}: NavigationProps) {
    const [animate, setAnimate] = useState(true);
    const { t } = useTranslation()
    const currentLanguage = resolvedLanguage()

    useEffect(() => {
        const navLinkLoad: string = "navLinkLoadDone"
        if (window.sessionStorage.getItem(navLinkLoad) === null) {
            setAnimate(true)
            window.sessionStorage.setItem(navLinkLoad, "1")
        } else {
            setAnimate(false)
        }
    }, [])

    return (
        <header className="header">
            <nav className={ animate ? "fade-in-nav-link header-nav" : "header-nav"}>
                { includeHome ? <HomeButton title={t('navigation.home')} /> : null }
                <ChangeLanguageButton code={ currentLanguage }/>
            </nav>
        </header>
    )
}

type NavigationProps = {
    includeHome: boolean
}

type HomeButtonType = {
    title: string
}

type ChangeLanguageButtonType = {
    code: languageCode
}



