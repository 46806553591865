import React from 'react';
import i18n from "i18next";

export enum languageCode {
    nb = 'nb',
    en = 'en'
}

export default function resolvedLanguage(): languageCode {
    const resolved = i18n.resolvedLanguage
    if (resolved.includes('no') || resolved.includes('nb')) return languageCode.nb
    else return languageCode.en
}
