import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { NavigationToolbar } from '../../components/navigation/navigation-toolbar.component'
import { Footer } from '../../components/footer/footer.component'

import './terms.styles.css'
import useAnalyticsTracking from '../../hooks/useAnalyticsTracking'

export const TermsContent = () => {
    const { t } = useTranslation()
    return (
        <div className="terms-content">
            <div className="terms-table">
                <h1>{ t("terms.title") }</h1>
                <p className="lastUpdated">{ t("terms.last-changed") } 23.01.22</p>
                <p className="tc-paragraph">{ t("terms.para1") }</p>
                <p className="tc-paragraph">{ t("terms.para2") }</p>
                <p className="tc-paragraph">{ t("terms.para3") } <Link to={"/privacy"}>getloadedapp.com/privacy</Link></p>
                <p className="tc-paragraph">{ t("terms.para4") }</p>
                <p className="tc-paragraph">{ t("terms.para5") }</p>
                <p className="tc-paragraph">{ t("terms.para6") }</p>
                <h1>{ t("terms.account-title") }</h1>
                <p className="tc-paragraph">{ t("terms.account-para1") }</p>
                <p className="tc-paragraph">{ t("terms.account-para2") }</p>
                <p className="tc-paragraph">{ t("terms.account-para3") }</p>
                <p className="tc-paragraph">{ t("terms.account-para4") }</p>
                <h1>{ t("terms.content-title") }</h1>
                <p className="tc-paragraph">{ t("terms.content-para1") }</p>
                <p className="tc-paragraph">{ t("terms.content-para2") }</p>
                <h1>{ t("terms.iap-title") }</h1>
                <p className="tc-paragraph">{ t("terms.iap-para1") }</p>
                <p className="tc-paragraph">{ t("terms.iap-para2") }</p>
                <p className="tc-paragraph">{ t("terms.iap-para3") }</p>
                <h1>{ t("terms.links-title") }</h1>
                <p className="tc-paragraph">{ t("terms.links-para1") }</p>
                <p className="tc-paragraph">{ t("terms.links-para2") }</p>
                <p className="tc-paragraph">{ t("terms.links-para3") }</p>
                <p className="tc-paragraph">{ t("terms.links-para4") }</p>
                <h1>{ t("terms.liability-title") }</h1>
                <p className="tc-paragraph">{ t("terms.liability-para1") }</p>
                <h1>{ t("terms.termination-title") }</h1>
                <p className="tc-paragraph">{ t("terms.termination-para1") }</p>
                <p className="tc-paragraph">{ t("terms.termination-para2") }</p>
                <h1>{ t("terms.law-title") }</h1>
                <p className="tc-paragraph">{ t("terms.law-para1") }</p>
                <p className="tc-paragraph">{ t("terms.law-para2") }</p>
                <h1>{ t("terms.changes-title") }</h1>
                <p className="tc-paragraph">{ t("terms.changes-para1") }</p>
                <h1>{ t("terms.translation-title") }</h1>
                <p className="tc-paragraph">{ t("terms.translation-para1") }</p>
                <h1>{ t("terms.amendments-title") }</h1>
                <p className="tc-paragraph">{ t("terms.amendments-para1") }</p>
                <p className="tc-paragraph">{ t("terms.amendments-para2") }</p>
                <p className="tc-paragraph">{ t("terms.amendments-para3") }</p>
                <h1>{ t("terms.acknowledgement-title") }</h1>
                <p className="tc-paragraph">{ t("terms.acknowledgement-para1") }</p>
                <h1>{ t("terms.contact-title") }</h1>
                <p className="tc-paragraph">{ t("terms.contact-para1") }</p>
            </div>
        </div>
    )
}

function Terms() {
    useAnalyticsTracking()
    return (
        <div className="wrapper">
            <NavigationToolbar includeHome={ true } />
            <TermsContent />
            <Footer />
        </div>
    );
}

export default Terms;
