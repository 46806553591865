import React from 'react'
import { useTranslation } from 'react-i18next'
import mixpanel from 'mixpanel-browser'

import { NavigationToolbar } from '../../components/navigation/navigation-toolbar.component'
import { Footer } from '../../components/footer/footer.component'

import './home.styles.css'
import LoadedApp from '../../assets/images/loaded_front.webp'
import useAnalyticsTracking from '../../hooks/useAnalyticsTracking'
import resolvedLanguage from "../../utils/resolvedLanguage";

const openAppStore = (url: string) => {
    mixpanel.track('Download App', { 'source': "App Store" });
    window.open(url, "_blank", "noopener noreferrer")
}

const AppStoreBadge = () => {
    const { t } = useTranslation()
    const currentLanguage = resolvedLanguage()
    return (
        <img className="appstore-badge"
             src={ `/assets/app_store_badge_${currentLanguage}.svg` }
             alt="Download on the App Store"
             onClick={() => openAppStore(t("home.app-store-link"))}
             width="160px"
             height="53px"
        />
    )
}

const AppShowcase = () => (
    <img className="app-showcase"
         src={LoadedApp}
         alt="Loaded app showcase"
         width="200px"
         height="475px"
    />
)

const HomeContent = () => {
    const { t } = useTranslation()
    return (
        <div className="home-content">
            <div className="home-content-containers">
                <div className="home-content-info">
                    <h1>{t("home.title")}</h1>
                    <p>{t("home.content")}</p>
                    <AppStoreBadge />
                </div>
                <AppShowcase />
            </div>
        </div>
    )
}

const Home = () => {
    useAnalyticsTracking()
    return (
        <div className="wrapper">
            <NavigationToolbar includeHome={ false } />
            <HomeContent />
            <Footer />
        </div>
        )
}

export default Home;
