import React, { Suspense } from 'react'
import { render } from 'react-dom'
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

import Home from './pages/home/home'
import Privacy from './pages/privacy/privacy'
import Terms from './pages/terms/terms'

import './i18n';
import './index.css'

mixpanel.init('aedc283ed456a965a5431059efe310ea', {debug: false});

const rootElement = document.getElementById("root");
render(
    <BrowserRouter>
        <Suspense fallback={<div />}>
            <App />
        </Suspense>
    </BrowserRouter>,
    rootElement
);

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="terms-of-use" element={<Terms />} />
            {/* Remove supporting old privacy urls after 1.7 2022 */}
            <Route path="privacy-policy.html" element={<Navigate to="/privacy" />} />
            <Route path="personvern.html" element={<Navigate to="/privacy" />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}
