import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import mixpanel from 'mixpanel-browser'

export default function useAnalyticsTracking() {
    let location = useLocation();
    useEffect(() => {
        mixpanel.track('Page View', {
            'location': location.pathname + location.search,
        });
    }, [location]);
}
